@use "./settings/sizes";
@use "./settings/spacing";
@import "utilities";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@font-face {
  font-family: "Global-Lynx";
  src: url("/assets/fonts/GlobalLynx/Global Linx.ttf");
  font-weight: 700;
  font-style: normal;
}

/* Color guide */
:root {
  --primary-lightest: #095d9b;
  --primary-light: #0c5080;
  --primary: #0E3B5E;
  --primary-dark: #041c33;
  --primary-darkest: #010e1a;

  --secondary-lightest: #bebdb3;
  --secondary-light: #bebdb3;
  --secondary: #a4a196;
  --secondary-dark: #6e6960;
  --secondary-darkest: #5b5651;

  --blue-lightest: #98d6f9;
  --blue-light: #5ac0ee;
  --blue: #0090df;
  --blue-dark: #005fab;
  --blue-darkest: #0E3B5E;

  --gray-lightest: #f6f5f5;
  --gray-light: #d1d0d0;
  --gray: #6e6d6c;
  --gray-dark: #454545;
  --gray-darkest: #262626;

  --white: #ffffff;
  --black: #000000;

  --error-light: #f13311;
  --error: #df2b0b;
  --error-dark: #a7240d;

  --success-light: #43e555;
  --success: #1acd2f;
  --success-dark: #0fa921;

  --warning-light: #ffdf1b;
  --warning: #ffc107;
  --warning-dark: #e29400;

  --danger-light: #ec474f;
  --danger: #dc3545;
  --danger-dark: #b7192c;

  --info-light: #34c8dc;
  --info: #17a2b8;
  --info-dark: #1789a3;

  --light-light:  #f8f9fa;
  --light: #eceff2;
  --light-dark: #d6dce1;

  --dark-light: #3d454d;
  --dark: #343a40;
  --dark-dark: #21262b;

  /* Box shadow */
  --box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  /* Container component */
  --container-width: #{sizes.$w-container};
  --container-max-width: #{max-w-container};

  @media (min-width: sizes.$mobile) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$tablet) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$desktop) {
    --container-max-width: #{sizes.$max-w-container};
  }

  /* Big Container component */
  --big-container-width: #{sizes.$w-big-container};
  --big-container-max-width: #{sizes.$max-w-big-container};

  @media (min-width: sizes.$mobile) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  @media (min-width: sizes.$tablet) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  @media (min-width: sizes.$desktop) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  /* Inputs */
  --input-border-radius: 5px;
  --input-height: 35px;
  --input-border: 1px solid var(--primary);
  --input-padding: 0rem 0.8rem;
  --input-focus-border: 1px solid var(--primary-lightest);
  --input-error: var(--error);
  --input-invalid-border: 1px solid var(--error);
  --input-error-placeholder: var(--error);
  --input-color: var(--primary);

  /* Transition */
  --timing: cubic-bezier(0.56, 0.37, 0.3, 1.01);
  --timing-reverse: cubic-bezier(0.99, 0.7, 0.63, 0.44);
}

/* Reseting tailwind */
@layer base {
  * {
    // font-family: 'Roboto Condensed', sans-serif;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: var(--blue);
    color: var(--white);
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  html {
    scroll-behavior: smooth;
    color: var(--gray-darkest);
  }

  .scrollbar-primary {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 100px;
      background: var(--gray-lightest);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      border: 6px solid rgba(0, 0, 0, 0.18);
      border-left: 0;
      border-right: 0;
      background-color: var(--primary-dark);
      border: 1px solid var(--blue-lightest);
    }
  }
  .scrollbar-primary-thin {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 100px;
      background: var(--gray-lightest);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      border: 6px solid rgba(0, 0, 0, 0.18);
      border-left: 0;
      border-right: 0;
      background-color: var(--primary-dark);
      border: 1px solid var(--blue-lightest);
    }
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  p {
    line-height: 1.25;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0;
  }
  input,
  textarea,
  select {
    border: 1px solid var(--primary);
    outline: none;
    height: 40px;
    color: var(--primary);
    border-radius: var(--input-border-radius);
    &:disabled {
      background-color: var(--gray-light);
      color: var(--gray-dark);
      cursor: not-allowed;
    }
  }
  textarea {
    height: 60px;
  }

  input[type="checkbox"] {
    width: 12px;
    height: 12px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:not(:checked) {
      opacity: 0.4;
    }
    &:checked {
      opacity: 1;
    }
  }

  fieldset {
    border: 1px solid var(--gray-dark);
  }

  ::placeholder {
    color: var(--medium);
  }
}

/* Components */
@layer components {
  .ease-default {
    transition-timing-function: var(--timing);
  }
  .loading-effect-animation {
    z-index: 1;
    position: relative;
    overflow: hidden;
  }
  .loading-effect-animation::after {
    content: "";
    position: absolute;
    top: 0;
    left: -500px;
    width: 1000px;
    height: 100%;
    background: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation-name: loading-effect;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: inherit;
  }
  @keyframes loading-effect {
    0% {
      left: -500px;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .child-span-blue span,
  .child-span-blue strong {
    color: var(--blue);
  }
  .child-span-blue-dark span,
  .child-span-blue-dark strong {
    color: var(--blue-dark);
  }
  .child-span-blue-darkest span,
  .child-span-blue-darkest strong {
    color: var(--primary);
  }
  .child-span-blue-light > span {
    color: var(--blue-light);
    font-weight: 800;
  }

  .decoration-blue-underline {
    position: relative;
    left: 0;
    width: 300px;
    max-width: 90% !important;
    border: none;
    border-top: 6px solid var(--blue-light);
    display: flex;
    align-items: center;
    margin: 1rem 0px;
    // Before element will be a dot at end of the after line
    &::before {
      content: "";
      position: absolute;
      left: calc(100% + 5px);
      transform: translateY(-3px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-center-blue-underline {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    background-color: var(--blue-light);
    margin: 1rem auto;
    // Before element will be a dot at center of the after line
    &::before {
      content: "";
      position: absolute;
      width: 140px;
      height: 6px;
      right: calc(100% + 5px);
      background: var(--blue-light);
    }
    &::after {
      content: "";
      position: absolute;
      width: 140px;
      height: 6px;
      left: calc(100% + 5px);
      background: var(--blue-light);
    }
  }
  .blue-line {
    position: relative;
    min-height: 6px;
    width: 300px;
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      border: 1px solid transparent;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-dark-blue-pill {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--primary);
      border-radius: 1000px;
      z-index: -1;
    }
  }
  .decoration-center-dark-blue-pill {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--primary);
      border-radius: 1000px;
      z-index: -1;
    }
  }

  .bg-blue-dark-degree-banner {
    background: linear-gradient(to top, rgb(2, 24, 40, 0.9) 0%, rgb(13, 56, 88, 0.1) 100%);
  }
  .bg-white-to-gray {
    background: linear-gradient(to right, #fff 50%, var(--gray-light) 50%);
  }
  .bg-gray-to-white {
    background: linear-gradient(to right, var(--gray-light) 50%, #fff 50%);
  }
  .bg-gray-degree {
    background: rgb(249, 250, 252);
    background: linear-gradient(180deg, rgba(249, 250, 252, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  .bg-blue-degree {
    background: rgb(21, 60, 93);
    background: linear-gradient(0deg, rgba(21, 60, 93, 1) 0%, rgba(37, 95, 146, 1) 100%);
  }
  .bg-blue-medium-degree {
    background: rgb(6, 79, 120);
    background: linear-gradient(0deg, rgba(6, 79, 120, 1) 0%, rgba(8, 102, 147, 1) 100%);
  }
  .bg-blue-light-degree {
    background: rgb(8, 102, 147);
    background: linear-gradient(0deg, rgba(8, 102, 147, 1) 0%, rgba(57, 141, 190, 1) 100%);
  }
  .bg-blue-dark-degree {
    background: #0c3554;
    background: linear-gradient(to top, #031b2c 10%, #0c3554 100%);
  }

  .bg-blue-darkest-to-b {
    background: #0d3858;
    background: linear-gradient(to bottom, #021828 10%, #0d3858 100%);
  }
  .bg-blue-darkest-to-t {
    background: #0d3858;
    background: linear-gradient(to top, #021828 10%, #0d3858 100%);
  }
  .bg-dark-gray-to-b {
    background: #4a4a4a;
    background: linear-gradient(to bottom, #272727 33%, #4b4b4b 100%);
  }
  .bg-dark-gray-to-t {
    background: #4a4a4a;
    background: linear-gradient(to top, #272727 33%, #4b4b4b 100%);
  }

  .select {
    height: 45px;
  }

  .animate-download {
    // animation: download 1s ease-in-out forwards;
    animation-name: download;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes download {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .socialLink {
    span {
      transition: 0.5s;
      border-radius: 1000px;
      vertical-align: middle;
      @apply text-white bg-primary;
      &:hover {
        @apply bg-blue-light;
      }
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
  .bg-tab-title {
    z-index: 1;
    position: relative;
    background-color: var(--primary);
  }
  .bg-tab-title::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(100%);
    width: 10px;
    height: calc(100%);
    z-index: 0;
    background: linear-gradient(220deg, transparent 20%, var(--primary) 20.1%);
  }
}

/* Utilities */
@layer utilities {
  .title-h1 {
    @apply leading-[1.15] text-[28px];
  }
  .title-h2 {
    @apply leading-[1.15] text-[24px];
  }
  .title-h3 {
    @apply leading-[1.15] text-[18px];
  }
  .title-h4 {
    @apply leading-[1.15] text-[16px];
  }
  .title-h5 {
    @apply leading-[1.27] text-[14px];
  }
  .title-h6 {
    @apply leading-[1.15] text-[12px];
  }
  .text-4xs {
    @apply text-[4px];
  }
  .text-3xs {
    @apply text-[6px];
  }
  .text-2xs {
    @apply text-[8px];
  }
  .text-xs {
    @apply text-[10px];
  }
  .text-sm {
    @apply text-[12px];
  }
  .text-base {
    @apply text-[14px];
  }
  .text-lg {
    @apply text-[16px];
  }
  .text-xl {
    @apply text-[18px];
  }
  .text-2xl {
    @apply text-[20px];
  }
  .text-3xl {
    @apply text-[24px];
  }
  .text-4xl {
    @apply text-[28px];
  }
  .text-5xl {
    @apply text-[32px];
  }
  .text-6xl {
    @apply text-[40px];
  }
  .text-7xl {
    @apply text-[48px];
  }
  .text-8xl {
    @apply text-[60px];
  }
  .text-9xl {
    @apply text-[72px];
  }

  .icon-4xs {
    @apply h-[4px] w-[4px];
  }
  .icon-3xs {
    @apply h-[6px] w-[6px];
  }
  .icon-2xs {
    @apply h-[8px] w-[8px];
  }
  .icon-xs {
    @apply h-[10px] w-[10px];
  }
  .icon-sm {
    @apply h-[12px] w-[12px];
  }
  .icon-base {
    @apply h-[14px] w-[14px];
  }
  .icon-lg {
    @apply h-[16px] w-[16px];
  }
  .icon-xl {
    @apply h-[18px] w-[18px];
  }
  .icon-2xl {
    @apply h-[20px] w-[20px];
  }
  .icon-3xl {
    @apply h-[24px] w-[24px];
  }
  .icon-4xl {
    @apply h-[28px] w-[28px];
  }
  .icon-5xl {
    @apply h-[32px] w-[32px];
  }
  .icon-6xl {
    @apply h-[40px] w-[40px];
  }
  .icon-7xl {
    @apply h-[48px] w-[48px];
  }
  .icon-8xl {
    @apply h-[60px] w-[60px];
  }
  .icon-9xl {
    @apply h-[72px] w-[72px];
  }

  .link-primary {
    @apply text-primary-light underline decoration-transparent hover:decoration-primary-light underline-offset-2 duration-300;
  }
  .leading-1 {
    line-height: 1 !important;
  }
}
/* Hr */
.blueLine {
  margin-top: 0.5rem;
  width: 300px;
  border: none;
  border-top: 4px solid var(--blue-light);
}

.grayLine {
  margin: 1.5rem auto;
  width: 100%;
  border: none;
  border-top: 1px solid var(--gray-light);
}

.blueDash {
  content: "";
  display: inline-block;
  width: 7.5px;
  margin-bottom: 6px;
  height: 2.5px;
  background-color: var(--blue-light);
}
.fade-appear {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.88, 0.1, 0.62, 0.97) forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.row-fade-appear {
  opacity: 0;
  animation: rowFadeIn 0.3s cubic-bezier(0.88, 0.1, 0.62, 0.97) forwards;
  animation-delay: 0.1s;
}

@keyframes rowFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.swal2-confirm.swal2-styled {
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: var(--primary-light);
  }
}

.react-datepicker__tab-loop {
  z-index: 10; // To prevent the datepicker from being hidden by other elements and labels
}

.react-datepicker__header {
  background: var(--primary) !important;
  .react-datepicker__current-month {
    color: white !important;
  }
  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}
.react-datepicker__input-container input {
  width: 100%;
}