.menu-wrapper {
  z-index: 1;
  display: none;
  &--active {
    display: block;
  }
}

.items-wrapper {
  @apply bg-white flex flex-col items-start justify-start text-blue-darkest gap-1 h-full pb-20 w-[95%];
  @apply text-[16px] 2xl:text-[20px] leading-[1.27];
}
.menu-layer {
  transform: translateX(-100%);
  opacity: 0;
  z-index: 1;  
}

.menu-item {
  @apply cursor-default;
  @apply relative flex justify-between items-center px-2 py-3 w-full border-y hover:text-blue-light border-gray-lightest;
  &.link {
    @apply cursor-pointer;
  }
}
.slide-from-left-animation {
  transform: translateX(-100%);
  &--active {
    animation: slide-from-left 0.3s forwards;
    animation-delay: 0.15s;
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-animation {
  animation: fade 0.15s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.icon-active {
  font-size: 50px;
  @apply absolute -right-5 text-transparent [transform:rotateY(90deg)] transition-all duration-300 ease-in-out;
}
.active {
  border-left: 4px solid var(--primary);
  transition: all 0.3s ease-in-out;
  @apply text-blue-light;
  & .icon-active {
    @apply text-primary-dark/10 [transform:rotateY(65deg)];
  }
}